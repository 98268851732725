/* SweetAlert */

.swal2-container {
  z-index: 1500;
}

.swal2-modal {
  @extend .d-flex;
  @include align-items(center);
  @include justify-content(center);
  @include flex-direction(column);
  @include border-radius(10px);
  &.swal2-popup {
    padding: 2.25rem;
  }

  .swal2-title {
    font-size: 25px;
    line-height: 1;
    font-weight: 600;
    color: $body-color;
    font-weight: initial;
    margin-bottom: 0;
  }

  .swal2-icon,
  .swal2-success-ring {
    margin-top: 0;
    margin-bottom: 42px;
  }

  .swal2-buttonswrapper {
    margin-top: 0;
    padding: 0;

    .swal2-styled {
      @extend .btn;
      margin-top: 0;
      font-weight: initial;

      &.swal2-confirm {
        @extend .btn;
        @extend .bg-success;
        @extend .border-success;
        font-weight: initial;
        margin-top: 32px;
      }

      &.swal2-cancel {
        @extend .btn;
        margin-top: 32px;
        border: 1px solid $border-color;
        color: $white;
        font-weight: initial;
      }
    }
  }

  .swal2-content {
    font-size: $default-font-size;
    font-weight: 600;
    color: $body-color;
    font-weight: initial;
    margin-top: 11px;
    width: 100%;
    .swal2-input {
      @extend .form-control;
    }
  }

  .swal2-actions {
    .swal2-styled {
      @extend .btn;
      &.swal2-cancel, &.swal2-confirm {
        font-size: $default-font-size;
        &:focus {
          box-shadow: none;
        }
      }
      &.swal2-cancel {
        @extend .bg-danger;
        &:hover { 
          @extend .text-white;
        }
      }
      &.swal2-confirm {
        @extend .bg-success;
        &:hover { 
          @extend .text-white;
        }
      }
    }
  }

  .swal2-close {
    font-size: 20px;
  }

  .swal2-success-ring {
    left: -30px;
  }
}