.questions_wrapper {

    table {
        i {
            color: $text-muted;
        }

        th {
            padding: 1.5rem;
        }

        td {
            padding: 1.5rem;
        }
    }
}
