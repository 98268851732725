.modal-dialog{
 max-width:800px;

 .modal-content{
    box-shadow: 1px 2px 10px #000;
 }

 h4{
     width:100%;
     text-align:center;
     font-size:25px;
     margin-top:0.5rem;
 }
 .close{
  margin:-10px;
  font-size:2.5rem;
 }
}
