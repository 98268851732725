@mixin shadow {
  border: 1px solid #00abcc;
  background-color: #00abcc;
  position: absolute;
  width: 84px;
  height: 79px;
  z-index: -1;
  filter: blur(4px);
  background: #ffffff 0% 0% no-repeat padding-box;

  background-color: #00000070;
}

@mixin wrapper {
  //   transition: 0.3s;
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1300;
  //   animation-duration: 0.3s;
  background-color: #00000070;
}

.popup-wrapper {
  @include wrapper();
  //   animation: jello-diagonal-1 0.3s both;
  transform: scale(1);
}
.popup-box {
  margin: auto;
  width: 600px;
  background-color: white;
  border-radius: 8px;
  position: relative;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 40px #00abcc1a;
  display: flex;
  flex-direction: column;
  align-items: center;

  .popupLogo {
    width: 200px;
  }

  p {
    text-align: center;
    margin-bottom: 0rem;
    display: flex;
    height: 100%;
    align-items: center;
    font-size: 18px;
  }
}

.corners {
  position: relative;
  display: inline-block;
}

.popup-wrapper-none {
  @include wrapper();
  // animation: jello-diagonal-2 0.3s both;
  // animation-duration: 0.3s;
  transform: scale(0);
}

@media only screen and (max-width: 510px) {
  .top-Left {
    width: 54px;
    height: 59px;
  }
  .top-Right {
    width: 54px;
    height: 59px;
  }

  .bottom-Left {
    width: 54px;
    height: 59px;
  }
  .bottom-Right {
    width: 54px;
    height: 59px;
  }

  .popup-box {
    margin: auto;
    width: 90%;
    margin-left: 5%;
    height: 150px;

    border-radius: 6px;
    p {
      padding: 0 35px;
      font-size: 2.5vw;
    }
  }
}
