.card_tab {
  .nav-tabs {
    border-bottom: 0;
  }
  .nav-link {
    border: 0;
    @media screen and (max-width: 767px) {
    }

    &.active {
      background-color: $dark;
    }

    img {
      height: 35px;
    }
  }
  .tab-content {
    border: 0;
    padding: $padding-0;
  }
  .question__select {
    width: 25%;

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
  .card__tab {
    @media screen and (max-width: 767px) {
      border: 0;
      margin-top: 2rem;
    }
  }

  .card-body {
    @media screen and (max-width: 767px) {
      padding: $padding-0 !important;
    }
  }
}
.forms-sample {
  select {
    border-radius: 10px !important;
    height: 40px;
    color: $text-muted;
    border: 1px solid $text-muted;
    width:100%;
  }
  option {
    color: $text-muted;
  }
  input,
  textarea {
    border-radius: 10px !important;
  }
}
