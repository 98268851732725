.users_wrapper {
  .users__heading {
    display: flex;
    > h4 {
      &:nth-child(2) {
        margin-left: 1.5rem;
      }
    }
  }
  table {
    th {
      padding: 1.8rem;
      small {
        position: absolute;
        top: 70%;
        left: 50%;
        transform: translate(-50%);
      }
    }
    td {
      button {
        background-color: transparent;
        border: 0;
      }
    }
    .number {
      margin-left: 0.5rem;
      font-size: 14px;
      margin-top: 5px;
    }
  }
}
